.isActive {
  background-color: transparent;
  color: var(--warning);
  border: none;
}
.isActive:hover {
  background-color: var(--warning-light);
  color: var(--warning);
  border: none;
}
.isSuspended {
  background-color: transparent;
  color: var(--secondary);
  border: none;
}
.isSuspended:hover {
  background-color: var(--secondary-light);
  color: var(--secondary);
  border: none;
}
.downloadQr{
  border-radius: 20px;
  background-color: #e2aa01;
  color: #1b1b1b;
  border: none;
  margin-left: 12px;
}
.downloadQr:hover {
  filter: brightness(1.1);
  background-color: #e2aa01 !important;
  color: #1b1b1b;
}
.downloadRuf{
  border-radius: 20px;
  background-color:#00a8ff;
  color: white;
  border: none;
  margin-left: 12px;
}
.downloadRuf:hover {
  filter: brightness(1.1);
  background-color: #00a8ff;
}

tr:hover {
  background-color: rgba(250, 250, 250, 0.1) !important
}


/* Status */
.statusContainer{
  display:flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  padding:10px 20px;
  border-radius:4px;
  margin-right:12px;
}
.status-2{
  background-color:var(--primary-light);
  color:var(--primary-dark);
}
.status-3{
  background-color:var(--secondary-light);
  color:var(--secondary);
}
.status-4{
  background-color:var(--tertiary-light);
  color:var(--tertiary);
}
.status-5{
    background-color:var(--success-light);
    color:var(--success-dark);
}
.status-1{
  background-color:var(--warning-light);
  color:var(--warning-dark);
}